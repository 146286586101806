@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap");

body {
  color: #fff;
  font-family: "Roboto Condensed", sans-serif;
  background-image: url('./sputnik-background.jpg');
  background-position: top top;
  background-size: cover;
  margin: 0;
}

.App {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background: rgb(49, 49, 177);
  background: linear-gradient(
    0deg,
    rgba(1, 20, 50, 1) 0%,
    rgba(1, 20, 50, 1) 50%,
    rgba(1, 20, 50, 0.4) 100%
  );
}

.container {
  width: 100%;
  margin: 15% auto;
}
.col-4 {
  width: 25%;
  float: left;
}

h1 {
  font-size: 38px;
  text-transform: uppercase;
  line-height: 1;
}
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  body {
    background-image: url('./sputnik-background-mobile.jpg') !important;    
  }
  .container{
    margin-top: 25%;
  }
}
@media (min-width: 768px) {
  .container {    
    width: 1100px;
  }
  h1 {
    font-size: 58px;
  }
}
